/** @format */

import {
  HomePageContent,
  HomepageNewsType,
  LandingPageContent,
  PageContent,
  PageContentElement,
  PageType,
} from '@/types/page';
import { useMemo } from 'react';

export const usePageContent = (
  page: PageType | HomepageNewsType | HomePageContent | LandingPageContent,
): PageContent => {
  const content = page.content.filter(x => x.fields);

  return useMemo(
    () =>
      content.map(x => {
        return {
          id: x.sys.contentType.sys.id,
          fields: x.fields,
        } as PageContentElement;
      }),
    [page],
  );
};
