/** @format */

import { useMediaQueryIndex } from '@/hooks/useMediaQuery';
import { FindColor } from '@/utils/findThemeColor';
import { Document } from '@contentful/rich-text-types';
import { Asset } from 'contentful';
import { FunctionComponent as FC, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Image } from '../Image';
import { ContainerRightSide, ContainerWrapper } from '../ui/Layout';
import { RichText } from '../ui/RichText';
import { PageName } from './Hero.style';

export const RealEstateManagementHero: FC<Props> = ({ title, name, image, background }) => {
  const theme = useContext(ThemeContext);
  const mediaQueryIndex = useMediaQueryIndex();

  const sm = useMemo(() => !!mediaQueryIndex && mediaQueryIndex < 1, [mediaQueryIndex]);

  const imageWidth = useMemo(() => {
    if (!mediaQueryIndex && mediaQueryIndex !== 0) return 5;
    if (sm) return 659;
    return 1377;
  }, [mediaQueryIndex]);

  const bg = useMemo(() => FindColor(background, theme?.color), [background]);

  return (
    <ContainerWrapper as="section" $color={bg}>
      <Container>
        <StyledPageName as="p">{name}</StyledPageName>
        <StyledImage image={image} width={imageWidth} placeholder={false} priority />
        <TextWrapper>
          <RichText text={title} />
        </TextWrapper>
      </Container>
    </ContainerWrapper>
  );
};

type Props = {
  title: Document;
  name: string;
  image: Asset;
  background: string;
};

const Container = styled(ContainerRightSide)`
  display: grid;
  justify-items: start;
  isolation: isolate;

  & > * {
    grid-column: 1;
    grid-row: 1;
  }

  max-height: 36.5rem;

  // 577px
  @media screen and (min-width: 36.063rem) {
    max-height: clamp(36.5rem, -1.2618rem + 104.712vw, 49rem);
  }

  //941px
  @media screen and (min-width: 941px) {
    max-height: 47.5rem;
  }
`;

const StyledImage = styled(Image)`
  --padding: ${({ theme }) => theme.container.smallPadding};
  display: block;
  position: relative;

  width: 659px;
  height: auto;

  z-index: -1;

  right: var(--padding);
  top: -1.25rem;

  //376px
  @media screen and (min-width: 376px) {
    top: -3.75rem;
    right: clamp(-10rem, 25.0366rem + -97.1551vw, 1.355rem);
  }

  // 577px
  @media screen and (min-width: 36.063rem) {
    width: clamp(41rem, -87.3901rem + 356.0209vw, 83.5rem);
    top: clamp(-10rem, 15.1309rem + -52.356vw, -3.75rem);
    right: clamp(-10rem, -40.2094rem + 83.7696vw, 0rem);
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    right: clamp(-3.5rem, 52.7745rem + -109.8039vw, 0rem);
  }

  //821px
  @media screen and (min-width: 51.313rem) {
    right: clamp(-6rem, 13.7479rem + -33.6134vw, -3.5rem);
  }

  //941px
  @media screen and (min-width: 941px) {
    right: clamp(-11rem, 50.6867rem + -96.3855vw, -6rem);
  }

  // 1025px
  @media screen and (min-width: 64.063rem) {
    right: clamp(-19rem, 54.6rem + -102.4vw, -11rem);
  }

  // 1151px
  @media screen and (min-width: 1151px) {
    right: clamp(-25rem, 4.8962rem + -33.218vw, -19rem);
  }
`;

const StyledPageName = styled(PageName)`
  position: relative;
  max-width: 7.5rem;
  top: 4.5rem;

  //376px
  @media screen and (min-width: 376px) {
    top: clamp(2rem, 71.6429rem + -285.7143vw, 4.5rem);
  }

  // 577px
  @media screen and (min-width: 36.063rem) {
    top: clamp(2rem, -5.2995rem + 29.9465vw, 5.5rem);
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    max-width: unset;
    top: clamp(5.5rem, -2.5392rem + 15.6863vw, 5rem);
  }

  // 1025px
  @media screen and (min-width: 64.063rem) {
    top: clamp(5.5rem, 3.45rem + 3.2vw, 5.75rem);
  }
`;

const TextWrapper = styled.div`
  align-self: flex-end;
  text-wrap: balance;
  position: relative;
  top: -3rem;

  em {
    display: block;
  }

  //376px
  @media screen and (min-width: 376px) {
    top: clamp(-5rem, 50.7143rem + -228.5714vw, -3rem);
  }

  // 577px
  @media screen and (min-width: 36.063rem) {
    top: clamp(-37rem, 51.4607rem + -184.2932vw, -15rem);
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    top: clamp(-39rem, -6.8431rem + -62.7451vw, -37rem);
  }

  //940px
  @media screen and (min-width: 940px) {
    align-self: flex-start;
    top: 11rem;
  }
`;
