/** @format */

import { useCallback, useContext, useEffect, useMemo, useState } from 'react';
import { ThemeContext } from 'styled-components';

const useMediaQueryIndex = () => {
  const theme = useContext(ThemeContext);
  const breakpoints = useMemo(() => (theme && theme.breakpoints) || {}, [theme]);

  const getIndex = useCallback(() => {
    return Object.values(breakpoints).filter(
      breakpoint => window.matchMedia(`screen and (min-width: ${breakpoint})`).matches,
    ).length;
  }, [breakpoints]);

  const [value, setValue] = useState<number | null>(null);

  useEffect(() => {
    const onResize = () => {
      setValue(getIndex());
    };

    onResize();
    window.addEventListener('resize', onResize);
    return () => window.removeEventListener('resize', onResize);
  }, [breakpoints, value]);

  return value;
};

export { useMediaQueryIndex };
