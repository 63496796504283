/** @format */

import { useMediaQueryIndex } from '@/hooks/useMediaQuery';
import { FindColor } from '@/utils/findThemeColor';
import { Document } from '@contentful/rich-text-types';
import { Asset } from 'contentful';
import { FunctionComponent as FC, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Image } from '../Image';
import { ContainerRightSide, ContainerWrapper } from '../ui/Layout';
import { RichText } from '../ui/RichText';
import { PageName } from './Hero.style';

export const AboutUsHero: FC<Props> = ({ title, name, image, background }) => {
  const theme = useContext(ThemeContext);
  const mediaQueryIndex = useMediaQueryIndex();

  const sm = useMemo(() => !!mediaQueryIndex && mediaQueryIndex < 1, [mediaQueryIndex]);
  const md = useMemo(() => !!mediaQueryIndex && mediaQueryIndex < 2, [mediaQueryIndex]);
  const lg = useMemo(() => !!mediaQueryIndex && mediaQueryIndex < 3, [mediaQueryIndex]);
  const xl = useMemo(() => !!mediaQueryIndex && mediaQueryIndex < 4, [mediaQueryIndex]);

  const imageWidth = useMemo(() => {
    if (!mediaQueryIndex && mediaQueryIndex !== 0) return 5;
    if (sm) return 475;
    if (md) return 670;
    if (lg) return 715;
    if (xl) return 742;
    return 742;
  }, [mediaQueryIndex]);

  const bg = useMemo(() => FindColor(background, theme?.color), [background]);

  return (
    <ContainerWrapper as="section" $color={bg}>
      <Container>
        <StyledPageName as="p">{name}</StyledPageName>
        <StyledImage image={image} width={imageWidth} placeholder={false} priority />
        <RichText text={title} />
      </Container>
    </ContainerWrapper>
  );
};

type Props = {
  title: Document;
  name: string;
  image: Asset;
  background: string;
};

const Container = styled(ContainerRightSide)`
  display: grid;

  & > *:not(h1) {
    grid-column: 1;
    grid-row: 1;
  }

  h1 {
    max-width: 22.5rem;
  }

  // 577px
  @media screen and (min-width: 36.063rem) {
    & > * {
      grid-column: 1;
      grid-row: 1;
    }

    h1 {
      align-self: flex-end;
      z-index: 1;
      margin-top: 35.5rem;
      max-width: 35rem;
    }
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    h1 {
      margin-top: 33.5rem;
    }
  }

  //821px
  @media screen and (min-width: 51.313rem) {
    h1 {
      padding-bottom: 5.75rem;
      margin-top: 33rem;
    }
  }

  //993px
  @media screen and (min-width: 62.063rem) {
    grid-template-columns: repeat(2, 1fr);

    h1 {
      grid-column: 1;
      grid-row: 1;
      margin-top: 0;
      padding-bottom: 19rem;
    }

    padding-bottom: 5.75rem;
  }

  //1202px
  @media screen and (min-width: 75.125rem) {
    padding-bottom: clamp(0rem, 34.7899rem + -38.6555vw, 5.75rem);
  }
`;

const StyledImage = styled(Image)`
  --padding: ${({ theme }) => theme.container.smallPadding};
  display: block;
  position: relative;

  left: calc(var(--padding) * -1);
  margin-bottom: 0.25rem;

  width: 457px;
  height: auto;

  //376px
  @media screen and (min-width: 376px) {
    width: calc(100% + var(--padding));
  }

  // 577px
  @media screen and (min-width: 36.063rem) {
    width: 670px;
    left: unset;
    right: clamp(-9.5rem, 4.5733rem + -29.3194vw, -6rem);
    align-self: flex-start;
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    width: 597px;
    right: clamp(-15rem, 71.7308rem + -169.2308vw, -9.5rem);
  }

  //821px
  @media screen and (min-width: 51.313rem) {
    width: 715px;
    right: clamp(-31.5rem, 38.086rem + -96.8153vw, -22rem);
  }

  //993px
  @media screen and (min-width: 62.063rem) {
    width: 742px;
    grid-row: 1/-1;
    grid-column: 2;
    right: clamp(-31.5rem, -17.8095rem + -19.0476vw, -30rem);
  }
  //1150px
  @media screen and (min-width: 1150px) {
    right: clamp(-40.75rem, 5.181rem + -51.0345vw, -31.5rem);
  }
`;

const StyledPageName = styled(PageName)`
  padding-bottom: 0;

  // 577px
  @media screen and (min-width: 36.063rem) {
    margin-top: 4.5rem;
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    margin-top: 6.5rem;
  }

  //821px
  @media screen and (min-width: 51.313rem) {
    margin-top: 8.5rem;
  }

  //993px
  @media screen and (min-width: 62.063rem) {
    grid-column: 1;
    grid-row: 1;
    margin-top: 6rem;
  }
`;
