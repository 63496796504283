/** @format */

import { useMediaQueryIndex } from '@/hooks/useMediaQuery';
import { FindColor } from '@/utils/findThemeColor';
import { Document } from '@contentful/rich-text-types';
import { Asset } from 'contentful';
import { FunctionComponent as FC, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Image } from '../Image';
import { ContainerRightSide, ContainerWrapper } from '../ui/Layout';
import { RichText } from '../ui/RichText';
import { PageName } from './Hero.style';

export const WorkWithUsHero: FC<Props> = ({ title, name, image, background }) => {
  const theme = useContext(ThemeContext);
  const mediaQueryIndex = useMediaQueryIndex();

  const sm = useMemo(() => !!mediaQueryIndex && mediaQueryIndex < 1, [mediaQueryIndex]);

  const imageWidth = useMemo(() => {
    if (!mediaQueryIndex && mediaQueryIndex !== 0) return 5;
    if (sm) return 475;
    return 897;
  }, [mediaQueryIndex]);

  const bg = useMemo(() => FindColor(background, theme?.color), [background]);

  return (
    <ContainerWrapper as="section" $color={bg}>
      <Container>
        <StyledPageName as="p">{name}</StyledPageName>
        <StyledImage image={image} width={imageWidth} placeholder={false} priority />
        <TextWrapper>
          <RichText text={title} />
        </TextWrapper>
      </Container>
    </ContainerWrapper>
  );
};

type Props = {
  title: Document;
  name: string;
  image: Asset;
  background: string;
};

const Container = styled(ContainerRightSide)`
  display: grid;
  justify-items: start;
  isolation: isolate;
  padding-bottom: 3.76rem;

  & > * {
    grid-column: 1;
    grid-row: 1;
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    max-height: 52rem;
  }

  // 1151px
  @media screen and (min-width: 1151px) {
    max-height: 44rem;
  }
`;

const StyledImage = styled(Image)`
  display: block;
  position: relative;
  width: 475px;
  height: auto;

  z-index: -1;

  top: 0;

  // 426px
  @media screen and (min-width: 26.625rem) {
    width: clamp(29.688rem, 5.914rem + 89.502vw, 48.875rem);
  }

  // 577px
  @media screen and (min-width: 36.063rem) {
    right: clamp(-11.75rem, 4.3338rem + -33.5079vw, -7.75rem);
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    width: clamp(48.875rem, -59.5088rem + 225.5059vw, 56.063rem);
    top: -3rem;
    right: -8rem;
  }

  //821px
  @media screen and (min-width: 51.313rem) {
    top: -2rem;
    right: clamp(-13.5rem, 29.5833rem + -73.3333vw, -8rem);
  }

  //941px
  @media screen and (min-width: 941px) {
    top: -2.5rem;
    right: clamp(-15.5rem, 8.881rem + -38.0952vw, -13.5rem);
  }

  // 1025px
  @media screen and (min-width: 64.063rem) {
    right: clamp(-26.5rem, 73.8968rem + -139.6825vw, -15.5rem);
  }

  // 1151px
  @media screen and (min-width: 1151px) {
    top: -1.5rem;
    right: clamp(-42.5rem, 36.9483rem + -88.2759vw, -26.5rem);
  }
`;

const StyledPageName = styled(PageName)`
  // 577px
  @media screen and (min-width: 36.063rem) {
    max-width: 5.5rem;
    margin-top: clamp(5rem, -1.0419rem + 16.7539vw, 7rem);
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    margin-top: 5rem;
  }

  //821px
  @media screen and (min-width: 51.313rem) {
    max-width: unset;
  }

  // 1151px
  @media screen and (min-width: 1151px) {
    margin-top: 6.5rem;
  }
`;

const TextWrapper = styled.div`
  align-self: flex-end;
  margin-top: 26.5rem;

  max-width: 22rem;

  em {
    display: block;
  }

  // 426px
  @media screen and (min-width: 26.625rem) {
    max-width: 25rem;
    margin-top: clamp(26.5rem, 4.1316rem + 84.2105vw, 34.5rem);
  }

  // 577px
  @media screen and (min-width: 36.063rem) {
    max-width: 27rem;
    margin-top: clamp(22rem, -6.699rem + 79.5812vw, 31.5rem);
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    margin-bottom: 8rem;
  }

  //821px
  @media screen and (min-width: 51.313rem) {
    max-width: 30rem;
    margin-top: initial;
    margin-bottom: 12rem;
  }

  //940px
  @media screen and (min-width: 940px) {
    margin-bottom: 9rem;
  }

  // 1025px
  @media screen and (min-width: 64.063rem) {
    margin-bottom: unset;
    margin-top: 12rem;
    margin-top: clamp(12rem, 121.5238rem + -152.381vw, 24rem);
    align-self: start;
    max-width: 32rem;
  }
  // 1151px
  @media screen and (min-width: 1151px) {
    max-width: 35rem;
  }
`;
