/** @format */

import styled from 'styled-components';

import Image from 'next/image';
import { FunctionComponent as FC } from 'react';
import Logo from '../../assets/logo-yellow-short.svg';

export const Loader: FC<{ height?: number; light?: boolean }> = ({ height = 8, light = false }) => {
  return (
    <Container $height={height}>
      <StyledLoader $light={light} />
      <Image src={Logo} alt="Joivy logo" width={128} height={128} />
    </Container>
  );
};

const Container = styled.div<{ $height: number }>`
  --_height: ${({ $height }) => $height}rem;
  --_translateX: calc(var(--_height) * -0.04);

  display: grid;
  place-items: center;

  & > * {
    grid-area: 1 / 1;
  }

  img {
    transform: translateX(var(--_translateX));
    width: calc(var(--_height) / 2);
    height: auto;
  }
`;

const StyledLoader = styled.div<{ $light: boolean }>`
  --_animateTime: 5s;
  --_bradius: 50%;

  height: var(--_height);
  aspect-ratio: 1;
  margin: 5% auto;
  border: 5px solid;

  animation: loader var(--_animateTime) infinite, rotation var(--_animateTime) infinite linear;

  @keyframes loader {
    0% {
      border-radius: 0;
      border-color: ${({ theme, $light }) => ($light ? theme.color.white : theme.color.teal.dark)};
    }
    25% {
      border-radius: var(--_bradius);
      border-color: ${({ theme }) => theme.color.teal.light};
    }
    50% {
      border-radius: 0;
      border-color: ${({ theme }) => theme.color.pink};
    }
    75% {
      border-radius: var(--_bradius);
      border-color: ${({ theme }) => theme.color.teal.light};
    }
    100% {
      border-radius: 0;
      border-color: ${({ theme, $light }) => ($light ? theme.color.white : theme.color.teal.dark)};
    }
  }

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
`;
