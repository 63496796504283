/** @format */

import styled from 'styled-components';
import { Kicker } from '../ui/Typography';

export const PageName = styled(Kicker)`
  &:after {
    content: '';
    margin-top: 0.75rem;
    display: block;
    width: 2.875rem;
    height: 0.10625rem;
    background-color: ${({ theme }) => theme.color.yellow};
  }
`;
