/** @format */

import { useMediaQueryIndex } from '@/hooks/useMediaQuery';
import { FindColor } from '@/utils/findThemeColor';
import { Document } from '@contentful/rich-text-types';
import { Asset } from 'contentful';
import { FunctionComponent as FC, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Image } from '../Image';
import { ContainerRightSide, ContainerWrapper } from '../ui/Layout';
import { RichText } from '../ui/RichText';
import { PageName } from './Hero.style';

export const RealEstateInvestmentsHero: FC<Props> = ({ title, name, image, background }) => {
  const theme = useContext(ThemeContext);
  const mediaQueryIndex = useMediaQueryIndex();

  const sm = useMemo(() => !!mediaQueryIndex && mediaQueryIndex < 1, [mediaQueryIndex]);
  const md = useMemo(() => !!mediaQueryIndex && mediaQueryIndex < 2, [mediaQueryIndex]);

  const imageWidth = useMemo(() => {
    if (!mediaQueryIndex && mediaQueryIndex !== 0) return 5;
    if (sm) return 433;
    if (md) return 906;
    return 828.5;
  }, [mediaQueryIndex]);

  const bg = useMemo(() => FindColor(background, theme?.color), [background]);

  return (
    <ContainerWrapper as="section" $color={bg}>
      <Container>
        <StyledPageName as="p">{name}</StyledPageName>
        <StyledImage image={image} width={imageWidth} placeholder={false} priority />
        <TextWrapper>
          <RichText text={title} />
        </TextWrapper>
      </Container>
    </ContainerWrapper>
  );
};

type Props = {
  title: Document;
  name: string;
  image: Asset;
  background: string;
};

const Container = styled(ContainerRightSide)`
  display: grid;
  justify-items: start;
  isolation: isolate;

  & > * {
    grid-column: 1;
    grid-row: 1;
  }

  // 577px
  @media screen and (min-width: 36.063rem) {
    padding-bottom: 3.76rem;
    max-height: 50rem;
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    max-height: 52rem;
  }

  //941px
  @media screen and (min-width: 941px) {
    max-height: 44rem;
  }
`;

const StyledImage = styled(Image)`
  display: block;
  position: relative;
  width: 27.063rem;
  height: auto;

  z-index: -1;

  top: 1rem;
  right: -1.5rem;

  // 426px
  @media screen and (min-width: 26.625rem) {
    width: clamp(27.063rem, -42.6712rem + 261.9126vw, 51.781rem);
  }

  // 577px
  @media screen and (min-width: 36.063rem) {
    top: 4rem;
    right: clamp(-9rem, 27.1885rem + -75.3927vw, 0rem);
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    width: 781px;
    top: 0;
    right: clamp(-13rem, 35.2353rem + -94.1176vw, -10rem);
  }

  //821px
  @media screen and (min-width: 51.313rem) {
    top: -1rem;
    right: clamp(-15.75rem, 39.5441rem + -94.1176vw, -8.75rem);
  }

  //941px
  @media screen and (min-width: 941px) {
    width: 906px;
    top: -6.5rem;
    right: clamp(-20rem, 41.6867rem + -96.3855vw, -15rem);
  }

  // 1025px
  @media screen and (min-width: 64.063rem) {
    top: -8.5rem;
    right: clamp(-24rem, 54.2rem + -108.8vw, -15.5rem);
  }

  // 1151px
  @media screen and (min-width: 1151px) {
    top: -1.75rem;
    right: clamp(-40.75rem, 43.9559rem + -94.1176vw, -23.75rem);
  }
`;

const StyledPageName = styled(PageName)`
  max-width: 8rem;

  // 577px
  @media screen and (min-width: 36.063rem) {
    margin-top: clamp(5rem, -2.5524rem + 20.9424vw, 7.5rem);
  }

  //821px
  @media screen and (min-width: 51.313rem) {
    margin-top: clamp(4rem, 158.7368rem + -294.7368vw, 7.5rem);
  }

  //941px
  @media screen and (min-width: 941px) {
    max-width: clamp(8rem, -105.3735rem + 192.7711vw, 18rem);
    margin-top: 7rem;
  }

  // 1025px
  @media screen and (min-width: 64.063rem) {
    max-width: unset;
  }
`;

const TextWrapper = styled.div`
  align-self: flex-end;
  margin-top: 26.5rem;

  max-width: 22rem;

  em {
    display: inline;
  }

  // 426px
  @media screen and (min-width: 26.625rem) {
    max-width: 25rem;
    margin-top: clamp(26.5rem, 4.1316rem + 84.2105vw, 34.5rem);
  }

  // 577px
  @media screen and (min-width: 36.063rem) {
    max-width: 27rem;
    position: relative;
    top: -6.5rem;
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    top: -10rem;
  }

  //821px
  @media screen and (min-width: 51.313rem) {
    max-width: 30rem;
    margin-top: initial;
    margin-bottom: 12rem;
  }

  // 1025px
  @media screen and (min-width: 64.063rem) {
    align-self: start;
    position: relative;
    top: 14rem;
    max-width: 32rem;
  }
  // 1151px
  @media screen and (min-width: 1151px) {
    max-width: 35rem;
  }
`;
