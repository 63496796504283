/** @format */

import { useMediaQueryIndex } from '@/hooks/useMediaQuery';
import { FindColor } from '@/utils/findThemeColor';
import { Document } from '@contentful/rich-text-types';
import { Asset } from 'contentful';
import { FunctionComponent as FC, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import { Image } from '../Image';
import { ContainerRightSide, ContainerWrapper } from '../ui/Layout';
import { RichText } from '../ui/RichText';
import { PageName } from './Hero.style';

export const DesignBuildHero: FC<Props> = ({ title, name, image, background }) => {
  const theme = useContext(ThemeContext);
  const mediaQueryIndex = useMediaQueryIndex();

  const sm = useMemo(() => !!mediaQueryIndex && mediaQueryIndex < 1, [mediaQueryIndex]);

  const imageWidth = useMemo(() => {
    if (!mediaQueryIndex && mediaQueryIndex !== 0) return 5;
    if (sm) return 578;
    return 815;
  }, [mediaQueryIndex]);

  const bg = useMemo(() => FindColor(background, theme?.color), [background]);

  return (
    <ContainerWrapper as="section" $color={bg}>
      <Container>
        <StyledPageName as="p">{name}</StyledPageName>
        <StyledImage image={image} width={imageWidth} placeholder={false} priority />
        <TextWrapper>
          <RichText text={title} />
        </TextWrapper>
      </Container>
    </ContainerWrapper>
  );
};

type Props = {
  title: Document;
  name: string;
  image: Asset;
  background: string;
};

const Container = styled(ContainerRightSide)`
  display: grid;
  justify-items: start;
  isolation: isolate;

  & > * {
    grid-column: 1;
    grid-row: 1;
  }

  max-height: 47rem;

  // 577px
  @media screen and (min-width: 36.063rem) {
    max-height: 49.625rem;
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    max-height: 48.875rem;
  }

  // 1025px
  @media screen and (min-width: 64.063rem) {
    max-height: 47.6875rem;
  }

  // 1151px
  @media screen and (min-width: 1151px) {
    max-height: 44rem;
  }
`;

const StyledImage = styled(Image)`
  --padding: ${({ theme }) => theme.container.smallPadding};
  display: block;
  position: relative;
  width: 578px;
  height: auto;

  z-index: -1;

  top: -0.5rem;
  right: calc(var(--padding) + 1rem);

  // 426px
  @media screen and (min-width: 26.625rem) {
    right: clamp(-7.5rem, 13.5166rem + -58.2781vw, -2rem);
  }

  // 577px
  @media screen and (min-width: 36.063rem) {
    width: clamp(36.125rem, -8.6242rem + 124.088vw, 50.938rem);
    top: -1.5rem;
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    top: -0.5rem;
    right: -8rem;
  }

  //821px
  @media screen and (min-width: 51.313rem) {
    width: 756px;
    top: 0;
    right: clamp(-15rem, 40.2941rem + -94.1176vw, -8rem);
  }

  //941px
  @media screen and (min-width: 941px) {
    width: 880px;
    width: clamp(47.25rem, -40.6145rem + 149.3976vw, 55rem);
    top: 1rem;
    right: clamp(-25.25rem, 101.2078rem + -197.5904vw, -15rem);
  }

  // 1025px
  @media screen and (min-width: 64.063rem) {
    right: clamp(-31rem, 21.9rem + -73.6vw, -25.25rem);
  }

  // 1151px
  @media screen and (min-width: 1151px) {
    width: clamp(55rem, 3.4483rem + 71.7241vw, 68rem);
    right: clamp(-36rem, -11.0865rem + -27.6817vw, -31rem);
    top: -2rem;
  }
`;

const StyledPageName = styled(PageName)`
  position: relative;
  max-width: 4.5rem;
  top: 1.7rem;

  //376px
  @media screen and (min-width: 376px) {
    top: clamp(1.25rem, -7.1679rem + 35.8209vw, 5.75rem);
    max-width: clamp(4.5rem, 3.5647rem + 3.9801vw, 5rem);
  }

  // 577px
  @media screen and (min-width: 36.063rem) {
    max-width: unset;
  }
`;

const TextWrapper = styled.div`
  position: relative;
  align-self: flex-end;
  text-wrap: balance;

  margin-top: 27rem;

  em {
    max-width: 100vw;
    display: block;
  }

  //376px
  @media screen and (min-width: 376px) {
    margin-top: 26rem;
  }

  // 577px
  @media screen and (min-width: 36.063rem) {
    margin-top: 32rem;
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    margin-top: 34rem;
  }

  //821px
  @media screen and (min-width: 51.313rem) {
    margin-top: 32rem;
  }

  //941px
  @media screen and (min-width: 941px) {
    align-self: start;
    margin-top: 12rem;
  }
`;
