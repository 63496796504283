/** @format */

import { useMediaQueryIndex } from '@/hooks/useMediaQuery';
import { Options } from '@contentful/rich-text-react-renderer';
import { BLOCKS, Document, MARKS } from '@contentful/rich-text-types';
import { Asset } from 'contentful';
import { FunctionComponent as FC, ReactNode, useContext, useMemo } from 'react';
import styled, { ThemeContext } from 'styled-components';
import theme from '../../theme/styled-theme';
import { Image } from '../Image';
import { ContainerRightSide, ContainerWrapper } from '../ui/Layout';
import { RichText } from '../ui/RichText';
import { H1, Subtitle } from '../ui/Typography';

export const HomeHero: FC<Props> = ({ image, title }) => {
  const theme = useContext(ThemeContext);
  const mediaQueryIndex = useMediaQueryIndex();

  const sm = useMemo(() => !!mediaQueryIndex && mediaQueryIndex < 1, [mediaQueryIndex]);
  const md = useMemo(() => !!mediaQueryIndex && mediaQueryIndex < 2, [mediaQueryIndex]);
  const lg = useMemo(() => !!mediaQueryIndex && mediaQueryIndex < 3, [mediaQueryIndex]);
  const xl = useMemo(() => !!mediaQueryIndex && mediaQueryIndex < 4, [mediaQueryIndex]);

  const imageWidth = useMemo(() => {
    if (!mediaQueryIndex && mediaQueryIndex !== 0) return 5;
    if (sm) return 578;
    if (md) return 1106;
    if (lg) return 1106;
    if (xl) return 945;
    return 1108;
  }, [mediaQueryIndex]);

  return (
    <ContainerWrapper as="section" $color={theme?.color.teal.dark}>
      <Container>
        <StyledImage image={image} width={imageWidth} placeholder={false} priority />
        <TextWrapper>
          <RichText text={title} customOptions={OPTIONS} />
        </TextWrapper>
      </Container>
    </ContainerWrapper>
  );
};

type Props = {
  image: Asset;
  title: Document;
};

const Container = styled(ContainerRightSide)`
  position: relative;

  h1,
  h1 + p {
    position: relative;
    top: -4.375rem;
  }

  h1 {
    max-width: 22.5rem;
  }

  p {
    font-weight: 400;
    max-width: 22.5rem;
  }

  // 577px
  @media screen and (min-width: 36.063rem) {
    display: grid;
    grid-template-rows: 38rem repeat(2, 1fr);
    max-height: 54rem;

    h1 {
      max-width: 29.5rem;
    }

    p {
      max-width: 31.75rem;
    }

    h1,
    h1 + p {
      position: relative;
      top: 0;
    }
  }

  // 769px
  @media screen and (min-width: 48.063rem) {
    grid-template-rows: 40.5rem repeat(2, 1fr);
    max-height: 62rem;
  }

  //821px
  @media screen and (min-width: 51.313rem) {
    grid-template-rows: 31rem repeat(2, 1fr);
    max-height: 53rem;
  }

  //993px
  @media screen and (min-width: 62.063rem) {
    grid-template-columns: 34rem repeat(2, 1fr);
    max-height: 44rem;

    h1 {
      max-width: 34.75rem;
    }
    p {
      max-width: 33.75rem;
    }
  }

  //1202px
  @media screen and (min-width: 75.125rem) {
    grid-template-columns: 36rem repeat(2, 1fr);
  }
`;

const StyledImage = styled(Image)`
  display: block;
  position: relative;

  // default styles: until 576px
  width: clamp(36.125rem, 2.0774rem + 139.6825vw, 69.125rem);
  height: auto;

  top: -4.563rem;
  left: -2.625rem;

  // 577px
  @media screen and (min-width: 36.063rem) {
    width: 144%;
    top: clamp(-20rem, 4.1257rem + -50.2618vw, -14rem);
    left: 15%;
    grid-column: 1/-1;
    grid-row: 1/3;
  }

  //821px
  @media screen and (min-width: 51.313rem) {
    width: 1106px;
    top: -23rem;
    left: 8.938rem;
  }

  //993px
  @media screen and (min-width: 62.063rem) {
    width: 910px;
    left: -4rem;
    top: -11rem;
    grid-column: 2/-1;
    grid-row: 1/-1;
  }

  // 1025px
  @media screen and (min-width: 64.063rem) {
    width: 945px;
    left: -5rem;
  }

  //1202px
  @media screen and (min-width: 75.125rem) {
    width: 1106px;
    left: 0rem;
    top: -15rem;
  }

  // 1441px
  @media screen and (min-width: 90.063rem) {
    grid-column: -1;
    top: -15rem;
  }
`;

const TextWrapper = styled.div`
  grid-column: 1/-1;
  grid-row: 2/-1;
  z-index: 3;

  margin-top: -4.88rem;

  // 577px
  @media screen and (min-width: 36.063rem) {
    margin-top: -5rem;
  }

  //821px
  @media screen and (min-width: 51.313rem) {
    margin-top: 0.5rem;
  }

  //993px
  @media screen and (min-width: 62.063rem) {
    align-self: center;
    margin-top: -2rem;
    grid-column: 1/2;
    grid-row: 1/-1;
  }
`;

const OPTIONS: Options = {
  renderMark: {
    [MARKS.ITALIC]: (text: ReactNode) => <em>{text}</em>,
  },
  renderNode: {
    [BLOCKS.HEADING_1]: (node, children) => <H1>{children}</H1>,
    [BLOCKS.HEADING_6]: (node, children) => (
      <Subtitle as="p" $color={theme.color.white}>
        {children}
      </Subtitle>
    ),
  },
};
